import React, { useState, useEffect } from 'react';
import CenteredContent from '../components/CenteredContent';
import ImagePearl from '../imgs/pearl-health.jpeg';
import ImageAmazon from '../imgs/amazon.jpeg';
import ImageFacebook from '../imgs/facebook.jpeg';
import ImageCooperHealth from '../imgs/cooper-health.jpeg';
import remarkMath from 'remark-math';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import { URL } from './constants';

function HomePage() {

  // fetch from backend
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch(`${URL}/api/blog/`)
      .then(res => res.json())
      .then(posts => setPosts(posts));
  }, []);


  return (
    <div>
        <CenteredContent>
<h1>About me</h1>

  <p>
    <strong>Current.</strong> I'm a data scientist and machine learning engineer, at the staff/principal level. I've built machine learning systems to solve problems in value-based health care and finance (Pearl Health, Cooper Health), robotics (Amazon Robotics), and social media (Meta/Instagram). I'm currently working in Reality Labs at Meta. I've been both a manager and an individual contributor.
  </p>
  <p>
    See <a href='/resume'>RESUME</a> for more details. 
  </p>
  <p>
    <strong>Investor.</strong> I'm an LP investor in software, AI, and robotics. 
  </p>
  <p>
    <strong>Founder</strong>. I've also founded and developed several online software companies.
  </p>
  <p>
    See <a href='/portfolio'>PORTFOLIO</a> for more details.
  </p>

    <p><strong>Research.</strong> I earned my PhD from UPenn, where I was a member of the <a href="https://ndg.asc.upenn.edu/">Network Dynamics Group</a>. I studied the complex dynamics of large social systems, such as the spread of health behavior, the emergence of social consensus, and the collective problem-solving ability of teams of researchers. My research has been published in <a href="http://science.sciencemag.org/content/360/6393/1116">Science</a>, <a href="http://www.pnas.org/content/early/2017/06/06/1615978114.abstract">Proceedings of the National Academy of Sciences</a>, <a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0237978">PLOS ONE</a>, and <a href="/publications">elsewhere</a>.
    </p>
    <p>
    See <a href='/publications'>PUBLICATIONS</a> for more details.
    </p>

    <hr></hr>

    <a href="/blog"><h1>Blog</h1></a>
      <p>
        {posts.map((post, index) => (
          <div key={post.id}>
          <a href={`/blog/${post.url_slug}`}><h2>{post.title}</h2></a>
          <p>{post.date_created}</p>
          <ReactMarkdown
            children={post.summary}
            remarkPlugins={[remarkMath]}
            rehypePlugins={[rehypeRaw, rehypeKatex]}
            />
            {index < posts.length - 1 && <hr />} {/* Insert <hr> except for the last item */}
        </div>
        ))}
      </p>
    
        </CenteredContent>
    </div>
  );
}

export default HomePage;
