import React from 'react';
import CenteredContent from '../components/CenteredContent';
import ImagePearl from '../imgs/pearl-health.jpeg';
import ImageAmazon from '../imgs/amazon.jpeg';
import ImageFacebook from '../imgs/facebook.jpeg';
import ImageCooperHealth from '../imgs/cooper-health.jpeg';
import ImageMeta from '../imgs/facebook_logo.jpeg'

function ResumePage() {
  return (
    <div>
        <CenteredContent>
<h1>Resume</h1>
<p>
    My resume is available at: <a href="https://www.linkedin.com/in/devonbrackbill/">LinkedIn</a>
</p>

<hr></hr>

<h2>Current</h2>
    <p>
        <img src={ImageMeta} alt="Meta logo" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
        <strong>Data Scientist (Staff) at Meta (Reality Labs).</strong>
    </p>
    <p>
        Input Data Scientist, including hands and controller tracking.
    </p>
    <p>
        <strong>Limited Partner</strong>. I invest in funds focused on software (typically SaaS), artificial intelligence, and robotics. For inquiries here, my email address is <strong>devon [.] brackbill [at] gmail [dot] com</strong>.
    </p>
    <p>
        <strong>Founder</strong>. I have built several SaaS businesses. See more at <a href="/portfolio">PORTFOLIO</a>.
    </p>

<hr></hr>
<h2>
    Previous work
</h2>
    <p>
        <img src={ImagePearl} alt="Pearl Health Logo" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
        <strong>Data Scientist (Staff/Principal) at Pearl Health.</strong>
    </p>
    <p>
        <a href="https://pearlhealth.com/">Pearl Health</a> helps primary care physicians transition to value-based care arrangements. I build machine learning systems to identify highly actionable interventions to reduce health care costs and improve patient outcomes. For example, I built models to predict <a href="https://pearlhealth.com/blog/healthcare-insights/predicting-and-preventing-future-ed-visits/">Preventable ED visits</a>, and <a href="https://pearlhealth.com/blog/healthcare-insights/managing-unplanned-admissions-for-patients-with-multiple-chronic-conditions/">future Inpatient admissions for patients with chronic conditions</a>. I ran these projects through all stages: from idea, to model creation, and finally to impact measurement. I also conducted research on <a href="https://pearlhealth.com/blog/healthcare-insights/revolutionizing-post-discharge-care-with-transitional-care-management/">Transitional Care Management</a> to develop new products to reduce hospital readmissions.
    </p>
<p>
    <img src={ImageAmazon} alt="Amazon logo" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
    <strong>Senior Data Scientist at Amazon Robotics.</strong> 
</p>
<p>
    I spent ~4 years at <a href="https://amazon.jobs/en/teams/amazon-robotics">Amazon Robotics</a>, where I built machine learning systems to optimize the flow of <a href="https://www.theverge.com/2018/1/2/16841786/amazon-prime-2017-users-ship-five-billion">billions of items</a> through Amazon's sortation centers and used discrete event simulation to develop algorithms and system optimizations. Concretely, I was the lead DS designing a novel robotic transportation system that increased throughput by 30%. I also worked on the Amazon <a href="https://en.wikipedia.org/wiki/Amazon_Air">Air Gateway</a> and <a href="https://www.aboutamazon.com/news/operations/welcome-to-the-amazon-air-hub">Air Hub</a> projects, where I focused on the system design and integration of two tightly coupled systems: the <a href="https://www.amazon.science/latest-news/amazon-robotics-see-robin-robot-arms-in-action">robotic induction systems</a> that place packages on robotic drives, and the robotic drives themselves. I developed metrics for these systems that were used across all Amazon Robotics, and I designed algorithms to optimally time the arrival of robotic drives at induction stations.
</p>
<p>
    <img src={ImageFacebook} alt="Facebook logo" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
    <strong>Data Scientist at Instagram for Content Moderation.</strong>
</p>
<p>
    As a data scientist at Instagram, I worked on content moderation, specifically the measurement and automated removal of objectionable content that violates <a href="https://www.facebook.com/communitystandards/">FB's community standards</a> using ML systems. Here, I saw the importance of developing measurement systems that can demonstrate improvement in a production environment at scale--and not just showing impressive metrics on a golden data set. Measurement has been a particular focus of my career. The problems in content moderation lie at the intersection of free speech and creating safe online communities.
</p>

<p>
    <img src={ImageCooperHealth} alt="Cooper Health logo" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
    <strong>Data Scientist at Cooper Health.</strong>
</p>
<p>
    I was a data scientist for a hospital in Camden, NJ working on population health. Our goal was to manage patient care to prevent them becoming sicker and incurring more expensive and intensive care. I built predictive models to forecast all-cause inpatient readmission risk, Ambulatory Care Sensitive Inpatient admissions, and other events to reduce unnecessary patient care. I also worked to integrate the Camden Health Information Exchange (HIE) into Cooper's Population Health workflow so that we could better manage patient care.
</p>
<hr></hr>
<h2>
    Education
</h2>
<p>
    {/* <img src={ImageAmazon} alt="Amazon logo" style={{ width: '50px', height: '50px', marginRight: '10px' }} /> */}
    <strong>PhD from UPenn</strong>. See more at <a href="/publications">PUBLICATIONS</a>.
</p>
<p>
    {/* <img src={ImageAmazon} alt="Amazon logo" style={{ width: '50px', height: '50px', marginRight: '10px' }} /> */}
    <strong>BA from McDaniel College</strong>.
</p>
        </CenteredContent>
    </div>
  );
}

export default ResumePage;
